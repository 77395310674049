import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';

import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const userRole = currentUser?.attributes?.profile?.publicData?.role;
  const displayName = user.attributes.profile.firstName;

  // State to manage selected language
  const [language, setLanguage] = useState('it'); // Default language is Italian

  // Handle language change and redirect to the appropriate domain
  const handleLanguageChange = event => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);

    // Redirect to the correct domain based on selected language
    if (selectedLanguage === 'en') {
      window.location.href = ''; //'https://clubjoy.com'; // English version
    } else {
      window.location.href = 'https://clubjoy.it'; // Italian version
    }
  };

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <div className={css.otherGreeting}>
            Oppure:
            <br />
            <NamedLink name="TeambuildingPage">
              <FormattedMessage id="TopbarDesktop.provider" />
            </NamedLink>
            <br />
            <NamedLink name="TeambuildingPage">
              <FormattedMessage id="TopbarDesktop.team" />
            </NamedLink>
          </div>
        </div>

        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>

        {/* Language Selector for Unauthenticated Users 
        <div className={css.languageSelectorContainer}>
        <select
  className={classNames(css.languageSelector, css.smallLanguageSelector)} // Add a new class here
  value={language}
  onChange={handleLanguageChange}
>
  <option value="it">It</option>
  <option value="en">Eng</option>
</select>
        </div>*/}
      </div>
    );
  }

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        {userRole === 'provider' && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('CMSPage'))}
            name="CMSPage"
            params={{ pageId: 'overview' }}
          >
            <FormattedMessage id="TopbarMobileMenu.overview" />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {userRole === 'provider' && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('TeambuildingPage'))}
          name="TeambuildingPage"
        >
          <FormattedMessage id="TopbarDesktop.team" />
        </NamedLink>

        {/* Language Selector for Authenticated Users 
        <div className={css.languageSelectorContainer}>
        <select
  className={classNames(css.languageSelector, css.smallLanguageSelector)} // Add a new class here
  value={language}
  onChange={handleLanguageChange}
>
  <option value="it">It</option>
  <option value="en">Eng</option>
</select>
        </div>*/}

        <div className={css.spacer} />
      </div>
      {userRole === 'provider' && (
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      )}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
